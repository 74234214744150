import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  numberPattern = /^[0-9\b]+$/;
  alphabetPattern = /^[a-zA-Z ]*$/;
  numspeccharPattern = /^[0-9@#$%^&*()]+$/;
  alphanumericPattern = /^[a-z0-9 ]+$/i;
  addressPattern = /^[a-z0-9\.\,\/\ ]+$/i;

  secretKey = "chubblife";

  private loginUrl = environment.baseBackendUrl + '/login';
  private getUserUrl = environment.baseBackendUrl + '/get-user';
  private getDataDocAgentUrl = environment.baseBackendUrl + '/samba';
  private getDataPDFAgentUrl = environment.baseBackendUrl + '/samba/to-pdf';
  private getDataSLQAgentUrl = environment.baseBackendUrl + '/samba/print-slq';
  private getDataDocStreamUrl = environment.baseBackendUrl + '/samba/stream';
  private getDataListDocPolicyUrl = environment.baseBackendUrl + '/samba/get-list-file';
  private getDataDocPolicyUrl = environment.baseBackendUrl + '/samba/download-policy-pdf';
  private getDataManisUrl = environment.baseBackendUrl + '/efpk/get-manis-data';
  private verifyOtpUrl = environment.baseBackendUrl + '/verification/challenge-otp';

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  login(loginForm: any) {
    return this.http.post<HttpResponse<any>>(this.loginUrl, loginForm).pipe(catchError(this.handleError));
  }

  verifyOtp(request: any) {
    return this.http.post(this.verifyOtpUrl, request).pipe(catchError(this.handleError));
  }

  getUserData(request: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.getUserUrl, httpOptions).pipe(catchError(this.handleError));
  }

  getDataManis() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.getDataManisUrl, httpOptions).pipe(catchError(this.handleError));
  }


  getDataManisAgentCode(dataAgentCode : any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.getDataManisUrl + '/' + dataAgentCode, httpOptions).pipe(catchError(this.handleError));
  }


  getDocumentAgent(data: any) {
    const fileReq = new HttpRequest(
      'POST',
      this.getDataDocAgentUrl,
      data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
        responseType: 'blob'
      },
    );
    return this.http.request(fileReq)
  }


  getDocumentSignature(data: any) {
    const fileReq = new HttpRequest(
      'GET',
      this.getDataDocAgentUrl,
      data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
        responseType: 'blob'
      },
    );
    return this.http.request(fileReq)
  }



  getDocumentToPdfAgent(data: any) {
    const fileReq = new HttpRequest(
      'POST',
      this.getDataPDFAgentUrl,
      data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
        responseType: 'blob'
      },
    );
    return this.http.request(fileReq)
  }


  getSLQToPdfAgent(data: any) {
    const fileReq = new HttpRequest(
      'POST',
      this.getDataSLQAgentUrl,
      data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
        responseType: 'blob'
      },
    );
    return this.http.request(fileReq)
  }


  getDocXml(data: any) {
    const fileReq = new HttpRequest(
      'POST',
      this.getDataDocAgentUrl,
      data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
        responseType: 'text'
      },
    );

    return this.http.request(fileReq)
  }

  getDocumentAgentStream(data: any) {
    const fileReq = new HttpRequest(
      'POST',
      this.getDataDocStreamUrl,
      data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
        responseType: 'blob'
      },
    );

    return this.http.request(fileReq)
  }

  getTiffDokPolicy(data: any) {
    const fileReq = new HttpRequest(
      'POST',
      this.getDataDocAgentUrl,
      data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
        responseType: 'arraybuffer'
      },
    );

    return this.http.request(fileReq)
  }

  getDocumentListFile(data: any) {
    const fileReq = new HttpRequest(
      'POST',
      this.getDataListDocPolicyUrl,
      data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
      },
    );

    return this.http.request(fileReq)
  }

  downloadPolicyPdf(data: any) {
    const fileReq = new HttpRequest(
      'POST',
      this.getDataDocPolicyUrl,
      data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
        responseType: 'blob'
      },
    );

    return this.http.request(fileReq)
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else if (error.status === 401) {
      // this.router.navigate(['login']);
    }
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, body was:`, error.error);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  getDataEncryption(data) {
    const dataEncryption = CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
    return dataEncryption;
  }

  private getDate(datetimeString) {
    const date = datetimeString.split('T');
    return date[0];
  }

  formatYYYYMMDD(date: Date) {
    return this.getDate(date.toISOString())
  }

  formatDateToLocalFomat(date) {
    let dateLocalFormat = this.getDate(date);
    dateLocalFormat = dateLocalFormat.split('-');
    dateLocalFormat = dateLocalFormat[2] + '-' + dateLocalFormat[1] + '-' + dateLocalFormat[0];
    return dateLocalFormat;
  }

  //dd-MM-yyyy
  formatJsDateToJavaReadableFormat(date: Date) {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    let JRF = dd + "/" + mm + "/" + yyyy;
    return JRF;
  }

  formatJsDateToJavaTimeReadableFormat(date: Date) {
    var HH = String(date.getHours()).padStart(2, '0');
    var mm = String(date.getMinutes()).padStart(2, '0');
    var ss = String(date.getSeconds()).padStart(2, '0');
    let JRF = HH + ":" + mm + ":" + ss;
    return JRF;
  }

  formatJsDateToJavaTableFormat(date: Date) {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    let JRF = `${yyyy}-${mm}-${dd}`;
    return JRF;
  }

  formatJsDateToEbaoFormat(date: Date) {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    let JRF = yyyy + "-" + mm + "-" + dd;
    return JRF;
  }

  prepareJsonForWebApi(object: Object) {
    return {
      'data': this.getDataEncryption(object)
    }
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
